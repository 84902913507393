.carousel{
    display: flex;
    justify-content:center ;
    width: 600px;
    height: 400px;
    align-items: center;
}

img{

    width: 620px;
    height: 400px;
}