.carousel{
    width: 620px;
    height: 380px;
    margin: auto;
}

@media only screen and (max-width: 768px) {
    .carousel{
        width: 400px;
        /* height: 280px; */
        margin: auto;
    }
}

.carousel-control-prev {
    height: 300px;
}

.carousel-control-next {
    height: 300px;
}

